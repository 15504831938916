// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

export default [
    {
        path: '/admin/setup',
        exact: true,
        component: () => import('./views/admin/setup/index'),
    },
    {
        path: '/admin/setup/progress',
        component: () => import('./views/admin/setup/progress'),
    },
    {
        path: '/admin/home',
        component: () => import('./views/admin/home/admin'),
    },
    {
        path: '/admin/about',
        component: () => import('./views/admin/about/index'),
    },
    {
        path: ['/admin/domain/list', '/admin/domain/add-domain'],
        dataPath: '/admin/domain/list',
        component: () => import('./views/admin/domain/list'),
    },
    {
        path: ['/admin/customer/domains/id/:id', '/admin/customer/add-domain/id/:id'],
        dataPath: '/admin/customer/domains/id/:id',
        component: () => import('./views/admin/customer/domains'),
    },
    {
        path: ['/admin/customer/subscriptions/id/:id', '/admin/customer/new-subscription/id/:id'],
        dataPath: '/admin/customer/subscriptions/id/:id',
        component: () => import('./views/admin/customer/subscriptions'),
    },
    {
        path: '/admin/reseller/list',
        component: () => import('./views/admin/reseller/list'),
    },
    {
        path: ['/admin/reseller/domains/id/:id', '/admin/reseller/add-domain/id/:id'],
        dataPath: '/admin/reseller/domains/id/:id',
        component: () => import('./views/admin/reseller/domains'),
    },
    {
        path: ['/admin/reseller/subscriptions/id/:id', '/admin/reseller/new-subscription/id/:id'],
        dataPath: '/admin/reseller/subscriptions/id/:id',
        component: () => import('./views/admin/reseller/subscriptions'),
    },
    {
        path: '/admin/server/tools',
        component: () => import('./views/admin/server/tools'),
    },
    {
        path: '/admin/sessions/list',
        component: () => import('./views/admin/sessions/list'),
    },
    {
        path: ['/admin/subscription/list', '/admin/subscription/create'],
        dataPath: '/admin/subscription/list',
        component: () => import('./views/admin/subscription/list'),
    },
    {
        path: ['/admin/help/tutorials', '/smb/help/tutorials'],
        component: () => import('./views/default/help/tutorials'),
    },
    {
        path: ['/smb/web/setup', '/smb/web/add-domain/create-webspace/true'],
        dataPath: '/smb/web/setup',
        component: () => import('./views/smb/web/setup'),
    },
    {
        path: ['/smb/web/view', '/smb/web/add-domain'],
        dataPath: '/smb/web/view',
        component: () => import(/* webpackChunkName: "views/smb/web/view" */'./views/smb/web/view'),
    },
    {
        path: '/smb/web/overview',
        component: () => import(/* webpackChunkName: "views/smb/web/view" */'./views/smb/web/view'),
    },
    {
        path: '/smb/statistics/details',
        component: () => import('./views/smb/statistics/details'),
    },
    {
        path: '/admin/task-manager',
        component: () => import('./views/admin/task-manager/index'),
    },
];
