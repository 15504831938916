// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Form as BaseForm } from '@plesk/ui-library';
import { createElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { redirect, redirectPost, api, showInternalError, escapeHtml, getComponent } from 'jsw';
import { toFormData } from 'helpers/form';
import JswComponent from './JswComponent';
import render from '../../jsw/render';

const clearMessages = root => {
    root.querySelectorAll('.field-errors').forEach(errors => {
        errors.style.display = 'none';
        errors.closest('.form-row')?.classList.remove('error');
        errors.querySelectorAll('.error-hint').forEach(error => {
            error.parentNode.removeChild(error);
        });
    });
};

const processFieldMessage = (root, prefix, name, message) => {
    let fieldErrors;
    const formElement = root.querySelector(`#${prefix.join('-')}`);
    fieldErrors = formElement ? formElement.parentNode.querySelector('.field-errors') : null;
    if (!fieldErrors) {
        fieldErrors = formElement ? formElement.closest('.form-row').querySelector('.field-errors') : null;
    }
    if (!fieldErrors) {
        fieldErrors = root.querySelector(`#${prefix.join('-')}-form-row`).querySelectorAll('.field-errors');
        fieldErrors = fieldErrors[fieldErrors.length - 1];
    }

    fieldErrors.closest('.form-row').classList.add('error');
    render(fieldErrors, `<span class="error-hint">${escapeHtml(message)}</span>`);
    fieldErrors.style.display = '';
};

const processFieldMessages = (root, messages, prefix) => {
    if (Array.isArray(messages)) {
        messages.forEach(message => {
            if (typeof message === 'string') {
                processFieldMessage(root, prefix, 'error', message);
            } else {
                prefix.push(name);
                processFieldMessages(root, message, prefix);
                prefix.pop();
            }
        });
    } else {
        Object.entries(messages).forEach(([key, value]) => {
            if (typeof value === 'string') {
                processFieldMessage(root, prefix, key, value);
            } else {
                prefix.push(key);
                processFieldMessages(root, value, prefix);
                prefix.pop();
            }
        });
    }
};

const Form = ({ children, id, action, onSubmit, embeddedForms, formPrefix, ...props }) => {
    const [errors, setErrors] = useState({});
    const [state, setState] = useState(null);

    useEffect(() => {
        embeddedForms.forEach(({ name }) => {
            const form = document.getElementById(`embedded-form-${name}`);
            clearMessages(form);

            const subFormErrors = (formPrefix ? errors[formPrefix] || {} : errors)[name] || {};
            processFieldMessages(form, subFormErrors, formPrefix ? [formPrefix, name] : [name]);
        });
    }, [errors, embeddedForms, formPrefix]);

    const prepareValues = values => {
        if (!embeddedForms.length) {
            return;
        }

        const formData = new FormData([...document.forms].find(({ id: formId }) => formId === id));
        for (const entry of formData.entries()) {
            const [key, value] = entry;
            if (embeddedForms.any(({ name }) => key.startsWith(`${formPrefix}[${name}]`) || key.startsWith(name))) {
                values[key] = value;
            }
        }
    };

    const handleSubmit = async (values, isApply) => {
        prepareValues(values);
        if (typeof onSubmit === 'function') {
            values = await onSubmit(values, isApply);
        }
        if (!values) {
            return;
        }

        setErrors({});
        setState(isApply ? 'apply' : 'submit');

        try {
            handleSubmitSuccess(await api.post(action || window.location.href, toFormData(values)));
        } catch (e) {
            setState(null);
            showInternalError(e);
        }
    };

    const handleSubmitSuccess = response => {
        if (response.componentType === 'Jsw.Task.ProgressBar.Item') {
            let onHide = null;
            if (response.redirect) {
                onHide = progressBarItem => {
                    const redirectUrl = (progressBarItem && progressBarItem._getConfigParam('redirect')) || response.redirect;
                    redirect(redirectUrl);
                };
            }
            getComponent('asyncProgressBarWrapper').progressDialog(response, { onHide });
            // Observer.append(task => {
            //     if (task.id === response.id) {
            //         setState(null);
            //     }
            // }, 'plesk:taskComplete');
            return;
        }

        const { redirect: url, postData, target, formMessages } = response;
        if (url) {
            if (state === 'apply') {
                document.location.reload();
            } else if (postData) {
                redirectPost(url, postData, target);
            } else {
                redirect(url, null, target);
            }
        } else {
            setState(null);
            setErrors(formMessages);
        }
    };

    return (
        <BaseForm
            {...props}
            id={id}
            onSubmit={handleSubmit}
            errors={errors}
            state={state}
        >
            {children}
            {embeddedForms.map(({ name, content }) => (
                <JswComponent
                    key={name}
                    id={`embedded-form-${name}`}
                >
                    {content}
                </JswComponent>
            ))}
        </BaseForm>
    );
};

Form.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
    action: PropTypes.string,
    onSubmit: PropTypes.func,
    embeddedForms: PropTypes.array,
    formPrefix: PropTypes.string,
};

Form.defaultProps = {
    children: null,
    id: undefined,
    action: undefined,
    onSubmit: null,
    embeddedForms: [],
    formPrefix: '',
};

export default Form;
