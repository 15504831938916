// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { LookUp } from './lookup';
import { Tooltip } from './tooltip';
import { redirect } from './form-redirect';
import render from './render';
import createElement from './createElement';

export class ObjectSwitcher extends LookUp {
    _initConfiguration(config) {
        super._initConfiguration({
            tag: 'span',
            cls: 'btn-group object-switcher',
            ...config,
        });

        this._listUrl = this._getConfigParam('listUrl', '');
        this._itemUrlTemplate = this._getConfigParam('itemUrlTemplate', '');
        this._owner = this._getConfigParam('owner', null);
    }

    _initCustomLookUpComponent() {
        render(this._componentElement, createElement('button', {
            type: 'button',
            class: 'btn dropdown-toggle',
            onrender: el => {
                this._customLookUpComponent = el;
            },
        }, '···'));
    }

    _getSearchMoreText(count) {
        return super._getSearchMoreText(count)
            + (this._listUrl ? ` ${this.lmsg('refineSearchMessage', { link: `<a href="${this._listUrl}">${this.lmsg('listOfAllObjects')}</a>` })}` : '');
    }

    _updateData(data) {
        this._clearData();

        let myData = [];

        if (null !== this._owner) {
            myData = data.filter(({ isSameParent }) => isSameParent == 1);

            data = data.filter(({ isSameParent }) => isSameParent != 1);
        }

        if (myData.length) {
            if (data.length) {
                render(this._dropdownList, createElement('li.dropdown-header', this.lmsg('myObjectsLabel', { name: this._owner })));
            }

            myData.each(item => {
                render(this._dropdownList, this._createItem(item));
            });

            if (data.length) {
                render(this._dropdownList, [
                    createElement('li.divider'),
                    createElement('li.dropdown-header', this.lmsg('otherObjectsLabel')),
                ]);
            }
        }

        data.each(item => {
            render(this._dropdownList, this._createItem(item));
        });

        this._addSearchMoreElement();
    }

    _addTooltips() {
        this._tooltip = Tooltip.init(this._customLookUpComponent, { text: this.lmsg('description') });
    }

    handleChange() {
        super.handleChange();

        redirect(this._itemUrlTemplate.interpolate(this._currentValue, /(^|.|\r|\n)(%%|%25%25)(\w+)(%%|%25%25)/));
    }
}
